var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("trigger",null,{"show":_vm.show}),_vm._v(" "),_c('transition',{attrs:{"name":"modal"}},[(_vm.showModal)?_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onOutsideClicked),expression:"onOutsideClicked"}],staticClass:"modal-container",class:[
            {
              'slide-left': _vm.slidingLeft,
              'slide-right': _vm.slidingRight,
              'slide-in': _vm.slideInOnMount,
              'rounded-2xl': _vm.rounded,
            },
            _vm.modalClass,
          ]},[_c('div',{staticClass:"modal-header",class:{ 'rounded-2xl': _vm.rounded },style:(_vm.headerStyle)},[_c('div',{staticClass:"modal-back-btn-wrapper"},[_c('transition',{attrs:{"name":"fade-fast"}},[(_vm.showBackBtn)?_c('span',{on:{"click":function($event){return _vm.$emit('back')}}},[_c('svg',{staticClass:"modal-header-btn",attrs:{"width":"17","height":"29","viewBox":"0 0 17 29","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M15 2L3 14.5L15 26.5","stroke":"white","stroke-width":"4","stroke-linecap":"round"}})])]):_vm._e()])],1),_vm._v(" "),_vm._t("header",function(){return [_c('h3',[_vm._v(_vm._s(_vm.title))])]}),_vm._v(" "),_c('div',{staticClass:"modal-close-btn-wrapper"},[_c('transition',{attrs:{"name":"fade-fast"}},[(_vm.showCloseBtn)?_c('span',{staticClass:"modal-header-btn",on:{"click":function($event){return _vm.close()}}},[_vm._v("×")]):_vm._e()])],1)],2),_vm._v(" "),_c('div',{staticClass:"modal-body",class:{ 'rounded-2xl': _vm.rounded }},[_vm._t("default",null,{"close":_vm.close})],2)])])]):_vm._e()])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }